import React, { useEffect, useState } from 'react';
import { REACTIVATE_FLOW } from '../../../constants/AdminFlows';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../components/inputs/select/CustomSelect';
import {
  generateDays,
  generateYears,
  generateMonths,
} from '../../../constants/Flow';
import moment from 'moment';
import { getToken } from '../../../config/token';
import { Request } from '../../../config/requests';
import { showToast } from '../../../config/helpers';
import Loading from '../../../components/common/Loading';
import { isMobileOnly } from 'react-device-detect';

const ReactivateContract = ({ setIsLoading, isLoading, handleClose, setIsDone }) => {
  const { t } = useTranslation();
  const token = getToken();

  const [selectedItem, setSelectedItem] = React.useState({
    label: 'E-Mail-Adresse',
    value: 'email',
  });
  const [selectedPet, setSelectedPet] = useState({});
  const [policy, setPolicy] = useState({});
  const [date, setDate] = useState({ year: null, month: null, day: null });
  const [errorMessage, setErrorMessage] = useState(null)
  const [years, setYears] = useState({
    label: moment().format('yy'),
    value: moment().format('yy'),
  });
  const [months, setMonths] = useState({
    label: moment().format('MMMM'),
    value: moment().format('MMMM'),
  });
  const [days, setDays] = useState({
    label: moment().format('DD'),
    value: moment().format('DD'),
  });

  const [newEmail, setNewEmail] = useState(null);
  const [policyNo, setPolicyNo] = useState(null);
  const [tariff, setTariff] = useState(null);
  const [premiumAmount, setPremiumAmount] = useState(0.0);
  const [selectedCoverage, setSelectedCoverage] = useState(null);
  const [paymentFrequency, setPaymentFrequency] = useState(null);
  const [payMonthFrequency, setPayMonthFrequency] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const findContract = async () => {
    setIsLoadingData(true);
    let mainUrl = `api/policy/${policyNo}`;
    let res = await Request({
      method: 'get',
      route: mainUrl,
      token: token,
    });
    res = await res.json();
    setPolicy(res);
    findPet(res.policy_id);
  };

  const findPet = async (policyId) => {
    let mainUrl = `api/users/v2/pet?policy_id=${policyId}`;
    await Request({
      method: 'get',
      route: mainUrl,
      token: token,
    })
      .then((res) => res.json())
      .then((data) => {
        setSelectedPet(data.data)
        setTariff(data.products.petInsurance.data.selectedPackage);
        setSelectedCoverage(data.products.petInsurance.data.selectedCoverage);
        setPayMonthFrequency(data.quote.schedules.payMonthFrequency);
        calculatePremiumAmount(
          policyId,
          data.products.petInsurance.data.selectedPackage,
          data.products.petInsurance.data.selectedCoverage,
          data.quote.schedules.payMonthFrequency,
        );
      }).finally(() => {
        setIsLoadingData(false);
      });
  };

  const reactivateContract = async () => {
    setIsUpdatingData(true)
    let mainUrl = `api/policy/recover_policy/${policy.policy_id}`;
    await Request({
      method: 'POST',
      route: mainUrl,
      token: token,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('updateContract failed')
        }
        setIsCompleted(true);
        return res.json();
      })
      .then((data) => {
        if (data && data.message) {
          setErrorMessage(data.message)
        } else {
          setIsDone(true)
        }
      })
      .catch((e) => {
        // showToast(t('somethingWentWrong'), { error: true });
      })
      .finally(() => {
        setIsUpdatingData(false)
      });
  }

  useEffect(() => {
    setDays({
      label: moment().add(1, 'days').format('DD'),
      value: moment().add(1, 'days').format('DD'),
    });
  }, [months]);

  const monthsOptions = React.useMemo(() => {
    return generateMonths(
      years.label,
      moment().add(10, 'year').format('YYYY-MM-DD')
    );
  }, [years.value]);

  useEffect(() => {
    setMonths(
      monthsOptions.some((el) => el.value == months.value)
        ? months
        : monthsOptions[monthsOptions.length - 1]
    );
  }, [monthsOptions]);

  const confirmPet = React.useMemo(() => {
    return Object.keys(selectedPet).length ? selectedPet.nameOfPet + ", " + tariff + " " + (selectedCoverage * 100) + "%, " + premiumAmount + "€" : ""
  }, [selectedPet, tariff, premiumAmount])

  const calculatePremiumAmount = async (policyId, t, cov, frequency) => {
    let coverage;
    switch (String(selectedCoverage)) {
      case '0.8':
        coverage = '80%';
        break;
      case '0.7':
        coverage = '70%';
        break;
      case '0':
        coverage = '100%';
        break;
      default:
        break;
    }
    let mainUrl = `api/policy/calculate/${policyId}`;
    await Request({
      method: 'GET',
      route: mainUrl,
      token: token,
    })
      .then((res) => res.json())
      .then((data) => {
        let coverage;
        switch (String(selectedCoverage)) {
          case '0':
            coverage = 1;
            break;
          default:
            coverage = selectedCoverage;
            break;
        }

        switch (frequency) {
          case 1:
            setPaymentFrequency('month');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 12 * 1.19
              ).toFixed(2)
            );

            break;
          case 3:
            setPaymentFrequency('quarter');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 4 * 1.19
              ).toFixed(2)
            );

            break;
          case 6:
            setPaymentFrequency('halfYear');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 2 * 1.19
              ).toFixed(2)
            );
            break;
          case 12:
            setPaymentFrequency('year');
            setPremiumAmount(
              (
                data[
                  String(t).split(' ')[0].toLocaleLowerCase() +
                  String(t).split(' ')[1]
                ].pricing[cov] / 1 * 1.19
              ).toFixed(2)
            );

            break;
          default:
            break;
        }
      })
      .finally(() => {
      });
  };

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(null)
      setSelectedPet({})
      setPolicy({})
    }
  }, [policyNo])

  if (isCompleted) {
    return (
      <>
        <div
          className="modal_container__body__text"
          style={{
            maxWidth: '500px',
            margin: '40px 0',
            textAlign: 'start',
          }}

        >
          {t('Well done! Contract successfully reactivated!')}
        </div>

        <div className="modal_container__body__row-modal">
          <div
            className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
            onClick={handleClose}
          >
            OK
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="wrapper w-100 d-flex gap-4 flex-column h-auto ">
      {REACTIVATE_FLOW
        // .filter(el => ((policy.policy_id && el?.formType != "search") || el?.formType == "search") ? el : undefined)
        .map((section, index) => {
          return (
            <>
              {(section.formType === 'search' ||
                (section.formType === 'result' && policy.policy_id))
                ? (
                  <div key={`section-${index}`} className="section-container w-100">
                    {(section.formType === 'result' && isLoadingData)
                      ? (
                        <Loading noText fast width={'100%'} scroll={false} />
                      ) : (
                        <>
                          {Object.entries(section.components).map(
                            ([key, step], stepIndex) => {
                              // Check if step is an object with a type property
                              if (typeof step === 'object' && step.type) {
                                if (
                                  (step.type === 'text_input' && !step.dependency) ||
                                  (step.type === 'text_input' &&
                                    step.dependency == selectedItem.value)
                                ) {
                                  return (
                                    <div
                                      key={`text-step-${stepIndex}`}
                                      className="modal_container__body__inputs align-items-start"
                                    >
                                      <label htmlFor={step.id}>{t(step.text)}</label>
                                      <input
                                        id={step.id}
                                        className="modal_container__body__input w-100"
                                        placeholder={t(step.placeholder)}
                                        required={step.required || false}
                                        disabled={step.disabled || false}
                                        onChange={(e) =>
                                          section.formType == 'search' &&
                                          !step.disabled &&
                                          setPolicyNo(e.target.value)
                                        }
                                        value={
                                          section.formType == 'search' && !step.disabled ? policyNo : confirmPet
                                        }
                                      />
                                    </div>
                                  );
                                }

                                if (step.type === 'button') {
                                  return (
                                    <div
                                      key={`button-step-${stepIndex}`}
                                      className="modal_container__body__inputs align-items-start"
                                    >
                                      <button
                                        id={step.id}
                                        disabled={
                                          policyNo?.length !== 9 || isLoadingData
                                        }
                                        onClick={() => {
                                          section.formType == 'search' && findContract();
                                        }}
                                        className={`button_red_small__admin ${step.btnType || ''
                                          }`}
                                      >
                                        {t(step.text)}
                                      </button>
                                    </div>
                                  );
                                }

                                if (step.type == 'select') {
                                  return (
                                    <>
                                      <div
                                        key={`button-step-${stepIndex}`}
                                        className="modal_container__body__inputs align-items-start"
                                      >
                                        <label htmlFor={step.id}>{t(step.text)}</label>
                                        <CustomSelect
                                          variant="modal-edit-data"
                                          options={step.options}
                                          value={selectedItem}
                                          onChange={(e) => {
                                            setSelectedItem(e);
                                          }}
                                          isClearable={true}
                                          toTranslate={false}
                                        />
                                      </div>
                                    </>
                                  );
                                }

                                if (
                                  (step.type == 'date' && !step.dependency) ||
                                  (step.dependency && step.dependency == selectedItem.value)
                                ) {
                                  return (
                                    <>
                                      <div
                                        key={`button-step-${stepIndex}`}
                                        className="modal_container__body__inputs align-items-start w-100 "
                                      >
                                        <label htmlFor={step.id}>{t(step.text)}</label>
                                        <div className="d-flex gap-3 w-100">
                                          <CustomSelect
                                            variant="modal-edit-data"
                                            options={generateDays(
                                              years.value,
                                              months.value
                                            )}
                                            formatCreateLabel={(i) => i}
                                            menuPlacement={'top'}
                                            placeholder={t('DD')}
                                            value={days}
                                            onChange={(e) => setDays(e)}
                                            isClearable={false}
                                            toTranslate={false}
                                          />
                                          <CustomSelect
                                            variant="modal-edit-data"
                                            options={monthsOptions}
                                            formatCreateLabel={(i) => i}
                                            placeholder={t('MM')}
                                            value={months}
                                            menuPlacement={'top'}
                                            onChange={(e) => setMonths(e)}
                                            isClearable={false}
                                            toTranslate={false}
                                          />
                                          <CustomSelect
                                            variant="modal-edit-data"
                                            options={generateYears(
                                              moment().add(10, 'year').format('YYYY')
                                            )}
                                            formatCreateLabel={(i) => i}
                                            placeholder={t('YY')}
                                            value={years}
                                            menuPlacement={'top'}
                                            onChange={(e) => setYears(e)}
                                            isClearable={false}
                                            toTranslate={false}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              }
                              return null; // Ignore non-object steps or steps without a type
                            }
                          )}
                        </>)}

                    {(section.formType === 'result' && selectedPet && !isLoadingData) &&
                      <>
                        {isUpdatingData
                          ? <Loading noText fast width={'100%'} scroll={false} />
                          : <button
                            onClick={() => { reactivateContract() }}
                            className={`button_red_small__admin w-100`}
                            style={{ marginBottom: '10px' }}
                            disabled={!days || !months || !years}
                          >
                            {t('Reactivate contract')}
                          </button>}
                      </>}
                  </div>
                )
                : null}
            </>
          );
        })}

      {errorMessage && <span style={{
        color: '#FE4B4E',
        fontWeight: '800',
      }}>{errorMessage}</span>}
    </div>
  );
};

export default ReactivateContract;
