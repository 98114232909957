import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile, isMobileOnly } from 'react-device-detect';
import ModalWrapper from '../../components/modalWrapper';
import Datepicker from '../../components/common/components/Datepicker';
import moment from 'moment';
import { Markup } from 'interweave';
import Attach from '../../assets/iconsSmall/paper_clip.svg';
import Remove from '../../assets/iconsSmall/cross_black.svg';
import Check from '../../assets/iconsSmall/pure_check.svg';
import { submitUpdates, requestPrice, sendUserRequest, sendTariffRequest } from './service';
import Loading from '../../components/common/Loading';
import { CHANGE_CONTRACT_FLOW } from '../changeContract/steps';

function ModalChangeContract({
  openModalChangeContract,
  setOpenModalChangeContract,
  petData,
  paymentFrequency,
}) {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState('main');
  const [flow, setFlow] = useState([]);
  const [step, setStep] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [priceList, setPriceList] = useState({});
  const [formData, setFormData] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [pendingPrice, setPendingPrice] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 30);

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name
      );
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  function handleClose() {
    setDialog('main');
    setCurrentStep(0);
    setPriceList({});
    setFormData({});
    setOpenModalChangeContract(false);
  }

  const handleNext = async () => {
    if (currentStep < selectedScenario.length - 1) {
      if (step.runSubmit) {
        try {
          await submitUpdates(
            petData.policy.id,
            formData.tariff || petData.products.petInsurance.data.selectedPackage,
            formData.deductible,
            moment(formData.date).format('DD.MM.YYYY'),
            setIsSubmitting,
          );
        } catch (error) {
          console.error('Error during submitUpdates:', error);
          handleClose();
        }
      }

      if (step.sendUserRequest) {
        try {
          await sendUserRequest(
            formData.message,
            selectedFiles,
            petData,
            setIsSubmitting,
          );
        } catch (error) {
          console.error('Error during submitUpdates:', error);
          handleClose();
        }
      }

      if (step.sendTariffRequest) {
        try {
          await sendTariffRequest(
            selectedFiles,
            petData,
            setIsSubmitting,
            formData,
          );
        } catch (error) {
          console.error('Error during submitUpdates:', error);
          handleClose();
        }
      }

      setCurrentStep((prev) => prev + 1);
    } else {
      setOpenModalChangeContract(false);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      if (selectedScenario === 'changeTariff' && currentStep === 1) {
        setFormData({});
      }
      if (step.type === 'list_coverage' && formData.deductible) {
        const { deductible, ...updatedFormData } = formData;
        setFormData(updatedFormData);
      }
      setCurrentStep((prev) => prev - 1);
    } else {
      setSelectedScenario(null);
      setDialog('main');
      setFormData({});
    }
  };

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    requestPrice(
      petData.policy.id,
      setPriceList,
      setPendingPrice,
      t
    );
  }, []);

  useEffect(() => {
    if (selectedScenario) {
      setFlow(CHANGE_CONTRACT_FLOW.scenarios[selectedScenario]);
    }
  }, [selectedScenario]);

  useEffect(() => {
    if (flow) {
      setStep(flow[currentStep]);
    }
  }, [flow, currentStep]);

  useEffect(() => {
    if (formData.health_status && formData.tariff) {
      const hasCoverages = formData.tariff.split(' ')[1] !== 'Full';
      const initialFlow = CHANGE_CONTRACT_FLOW.scenarios[selectedScenario];
      let additionalFlow = [];

      switch (formData.health_status) {
        case 'no_illness':
        case 'no_illness_6month':
          additionalFlow =
            CHANGE_CONTRACT_FLOW.scenarios[
            hasCoverages
              ? 'changeTariff_noIllness_withCoverage'
              : 'changeTariff_noIllness_withoutCoverage'
            ];
          setFlow(initialFlow.concat(additionalFlow));
          break;
        case 'had_illness':
          additionalFlow =
            CHANGE_CONTRACT_FLOW.scenarios[
            hasCoverages
              ? 'changeTariff_hadIllness_withCoverage'
              : 'changeTariff_hadIllness_withoutCoverage'
            ];
          setFlow(initialFlow.concat(additionalFlow));
          break;
      }
    }
  }, [formData]);

  const renderStep = () => {
    if (dialog === 'main') {
      return (
        <>
          <div
            className="modal_container__body__text"
            style={{ maxWidth: 'unset' }}
          >
            {t('whatToChange')}
          </div>

          <div className="modal_container__body__inputs">
            {/* {petData.products.petInsurance.data.selectedPackage !== 'OP Full' &&
              petData.products.petInsurance.data.selectedPackage !==
              'Health Full' && (
                <div
                  className={`modal_container__body__button-modal wide ${selectedScenario === 'changeDeductible' ? 'selected' : ''}`}
                  onClick={() => setSelectedScenario('changeDeductible')}
                >
                  {t('changeDeductible')}
                </div>
              )} */}
            <div
              className={`modal_container__body__button-modal wide ${selectedScenario === 'changeTariff' ? 'selected' : ''}`}
              onClick={() => setSelectedScenario('changeTariff')}
            >
              {t('changeTariff')}
            </div>
            <div
              className={`modal_container__body__button-modal wide ${selectedScenario === 'otherEnquiry' ? 'selected' : ''}`}
              onClick={() => setSelectedScenario('otherEnquiry')}
            >
              {t('otherEnquiry')}
            </div>
          </div>

          <div
            className="modal_container__body__row-modal"
            style={{ margin: '20px 0 0 0' }}
          >
            <div
              className={
                selectedScenario !== null
                  ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                  : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
              }
              onClick={
                selectedScenario !== null
                  ? () => setDialog('typeSelected')
                  : (e) => e.stopPropagation()
              }
            >
              {t('Next')}
            </div>
          </div>
        </>
      );
    }

    if (dialog === 'typeSelected') {
      return (
        <>
          {step.title && (
            <div
              className="modal_container__body__text"
            // style={{ maxWidth: 'unset' }}
            >
              {t(step.title)}
            </div>
          )}

          {step.type === 'list_coverage' && (
            <>
              <div className="modal_container__body__inputs">
                <div className="modal_container__body__row-modal">
                  {step.buttons
                    .filter((el) => {
                      const isOPBasic =
                        (step.isExistingCoverage
                          ? petData.products.petInsurance.data.selectedPackage
                          : formData.tariff) === 'OP Basic';

                      // Exclude the current coverage if `step.isExistingCoverage` is true
                      if (
                        step.isExistingCoverage &&
                        el.value ===
                        petData.products.petInsurance.data.selectedCoverage
                      ) {
                        return false;
                      }

                      // For OP Basic, include all options (except the current coverage already excluded above)
                      if (isOPBasic) {
                        return true;
                      }

                      // Exclude the option with `value === 0.7` for other tariffs
                      if (el.value === 0.7) {
                        return false;
                      }

                      // Include all other options
                      return true;
                    })
                    .map((option, _, filteredButtons) => (
                      <div
                        key={option.value}
                        className={`modal_container__body__button-modal ${isMobile ? 'wide' : ''} ${formData?.deductible === option.value ? 'selected' : ''}`}
                        style={
                          filteredButtons.length > 2
                            ? { width: 'unset', padding: '10px 30px' }
                            : {}
                        }
                        onClick={() => handleChange('deductible', option.value)}
                      >
                        {option.label}
                      </div>
                    ))}
                </div>
              </div>

              {formData.deductible ? (
                <>
                  <div
                    className="modal_container__body__text"
                    style={{ maxWidth: 'unset', margin: '0' }}
                  >
                    {t(step.message1)}
                  </div>

                  {pendingPrice ? (
                    <Loading noText fast scroll={false} />
                  ) : (
                    <div className="modal_container__body__amount">
                      {`${(priceList[
                        formData.tariff ||
                        petData.products.petInsurance.data.selectedPackage
                      ][formData.deductible] ||
                        priceList[
                        formData.tariff ||
                        petData.products.petInsurance.data.selectedPackage
                        ][0.7] ||
                        priceList[
                        formData.tariff ||
                        petData.products.petInsurance.data.selectedPackage
                        ][0.8] ||
                        priceList[
                        formData.tariff ||
                        petData.products.petInsurance.data.selectedPackage
                        ][1]).toFixed(2).replace('.', ',')
                        } €/${t(paymentFrequency)}`}
                    </div>
                  )}
                </>
              ) : null}

              <div
                className="modal_container__body__row-modal"
                style={{ margin: '20px 0 0 0' }}
              >
                <div
                  className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                  onClick={handleBack}
                >
                  {t('Back')}
                </div>
                <div
                  className={
                    formData.deductible
                      ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                      : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
                  }
                  onClick={
                    formData.deductible
                      ? handleNext
                      : (e) => e.stopPropagation()
                  }
                >
                  {t('Next')}
                </div>
              </div>
            </>
          )}

          {step.type === 'list_available_tariffs' && (
            <>
              <div className="modal_container__body__inputs">
                <div
                  className="modal_container__body__inputs"
                  style={{ margin: '0' }}
                >
                  {(formData.health_status === 'had_illness'
                    ? step.buttons.slice(
                      0,
                      step.buttons.findIndex(
                        (item) =>
                          item.value ===
                          petData.products.petInsurance.data.selectedPackage
                      )
                    )
                    : step.buttons
                  )
                    .filter(
                      (el) =>
                        el.value !==
                        petData.products.petInsurance.data.selectedPackage
                    )
                    .map((option) => (
                      <div
                        key={option.value}
                        className={`modal_container__body__button-modal ${isMobile ? 'wide' : ''} ${formData?.tariff === option.value ? 'selected' : ''}`}
                        onClick={() => handleChange('tariff', option.value)}
                      >
                        {t(option.label)}
                      </div>
                    ))}
                </div>
              </div>


              {formData.tariff ? (
                <>
                  {step.message1 && (
                    <div
                      className="modal_container__body__text"
                      style={{ maxWidth: 'unset', margin: '0' }}
                    >
                      {t(step.message1)}
                    </div>
                  )}
                  {pendingPrice ? (
                    <Loading noText fast scroll={false} />
                  ) : (
                    <div className="modal_container__body__amount">
                      {`${(priceList[formData.tariff][0.7] ||
                        priceList[formData.tariff][0.8] ||
                        priceList[formData.tariff][1]).toFixed(2).replace('.', ',')
                        } €/${t(paymentFrequency)}`}
                    </div>
                  )}
                </>
              ) : null}

              <div
                className="modal_container__body__row-modal"
                style={{ margin: '20px 0 0 0' }}
              >
                <div
                  className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                  onClick={handleBack}
                >
                  {t('Back')}
                </div>
                <div
                  className={
                    formData.tariff
                      ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                      : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
                  }
                  onClick={
                    formData.tariff ? handleNext : (e) => e.stopPropagation()
                  }
                >
                  {t('Next')}
                </div>
              </div>
            </>
          )}

          {step.type === 'datepicker' && (
            <>
              <div
                className="modal_container__body__inputs"
                style={{ margin: '0' }}
              >
                <div className="modal_container__body__dropdowns">
                  <Datepicker
                    date={formData.date || null}
                    setDate={(newValue) => handleChange('date', newValue)}
                    disabled={false}
                    type={'reset-dp-modal'}
                    minDate={tomorrow}
                    maxDate={maxDate}
                  />
                </div>
              </div>

              <div
                className="modal_container__body__row-modal"
                style={{ margin: '20px 0 0 0' }}
              >
                <div
                  className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                  onClick={handleBack}
                >
                  {t('Back')}
                </div>
                <div
                  className={
                    formData.date
                      ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                      : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
                  }
                  onClick={
                    formData.date ? handleNext : (e) => e.stopPropagation()
                  }
                >
                  {t('Next')}
                </div>
              </div>
            </>
          )}

          {step.type === 'date_confirm' && (
            <>
              <div
                className="modal_container__body__amount"
                style={{ margin: '0 0 20px 0' }}
              >
                {formData.date
                  ? moment(formData.date).format('DD.MM.YYYY')
                  : moment(petData.quote.policy.dateExpiry)
                    .add(1, 'day')
                    .format('DD.MM.YYYY')}
              </div>
              <div
                className="modal_container__body__text"
                style={{ maxWidth: 'unset' }}
              >
                {t(step.message1)}
              </div>

              <div
                className="modal_container__body__row-modal"
                style={{ margin: '20px 0 0 0' }}
              >
                <div
                  className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                  onClick={handleBack}
                >
                  {t('Back')}
                </div>
                <div
                  className={`button_red_small ${isSubmitting ? 'disabled' : ''} ${isMobileOnly ? 'mobile' : ''}`}
                  onClick={isSubmitting ? null : handleNext}
                >
                  {isSubmitting
                    ? <Loading noText fast scroll={false} size={26} color='#fff' />
                    : 'OK'}
                </div>
              </div>
            </>
          )}

          {step.type === 'input_attach' && (
            <>
              <div className="modal_container__body__inputs">
                <textarea
                  className="modal_container__body__input"
                  style={{ height: isMobileOnly ? '130px' : '230px' }}
                  placeholder={t(step.placeholder_input)}
                  value={formData?.message}
                  onChange={(e) => handleChange('message', e.target.value)}
                />
              </div>

              {selectedFiles.length
                ? selectedFiles.map((file, ind) => (
                  <div
                    key={ind}
                    className="modal_container__body__attach"
                    style={{ maxWidth: '540px', margin: '0 0 20px 0' }}
                  >
                    <img
                      src={Attach}
                      width={isMobile ? 30 : 40}
                      alt="Attach"
                    />
                    <input
                      readOnly
                      className="modal_container__body__input-file"
                      placeholder={t('addDocument')}
                      value={file.name || ''}
                    />
                    <img
                      src={Remove}
                      alt="Remove"
                      style={{
                        position: 'absolute',
                        right: '12px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        setSelectedFiles((prevSelectedFiles) =>
                          prevSelectedFiles.filter(
                            (el) => el.name !== file.name
                          )
                        )
                      }
                    />
                  </div>
                ))
                : null}

              <div
                className="modal_container__body__attach"
                style={{ maxWidth: '540px', margin: '0 0 20px 0' }}
                onClick={handleButtonClick}
              >
                <img
                  src={Attach}
                  className="pointer"
                  width={isMobile ? 30 : 40}
                  alt="Attach"
                />
                <input
                  readOnly
                  className="modal_container__body__input-file"
                  placeholder={t(step.placeholder_attach)}
                />
                <input
                  className="modal_container__body__input-file"
                  placeholder={t('addDocument')}
                  type="file"
                  ref={fileInputRef}
                  // accept=".jpg,.png"
                  onChange={handleFileChange}
                  hidden
                  multiple
                />
              </div>

              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                  onClick={handleBack}
                >
                  {t('Back')}
                </div>
                <div
                  className={`button_red_small ${(!formData.message || isSubmitting) ? 'disabled' : ''} ${isMobileOnly ? 'mobile' : ''}`}
                  onClick={(!formData.message || isSubmitting) ? null : handleNext}
                >
                  {isSubmitting
                    ? <Loading noText fast scroll={false} size={26} color='#fff' />
                    : t('Next')}
                </div>
              </div>
            </>
          )}

          {step.type === 'radio' && (
            <>
              <div
                className="modal_container__body__inputs"
                style={{ gap: 'unset', margin: '0 0 20px 0' }}
              >
                {step.options.map((option) => (
                  <div
                    key={`key-${option.value}`}
                    className="modal_container__body__radio-check"
                  >
                    <label>
                      <input
                        type="radio"
                        value={option.value}
                        checked={formData.health_status === option.value}
                        onChange={(e) =>
                          handleChange('health_status', e.target.value)
                        }
                      />
                      <span
                        style={{
                          backgroundColor:
                            formData.health_status === option.value
                              ? 'var(--b-color-bg-main-1)'
                              : 'transparent',
                        }}
                      >
                        <img
                          src={Check}
                          alt="Radio-button"
                          style={{
                            height:
                              formData.health_status === option.value
                                ? '28px'
                                : '0',
                            transform:
                              formData.health_status === option.value
                                ? 'rotate(0deg)'
                                : 'rotate(-45deg)',
                          }}
                        />
                      </span>
                      <div>{t(option.label)}</div>
                    </label>
                  </div>
                ))}
              </div>

              <div
                className="modal_container__body__row-modal"
                style={{ margin: '20px 0 0 0' }}
              >
                <div
                  className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                  onClick={handleBack}
                >
                  {t('Back')}
                </div>
                <div
                  className={
                    formData.health_status
                      ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                      : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
                  }
                  onClick={
                    formData.health_status
                      ? handleNext
                      : (e) => e.stopPropagation()
                  }
                >
                  {t('Next')}
                </div>
              </div>
            </>
          )}

          {step.type === 'thank_you' && (
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: '500px',
                  margin: '40px 0',
                  textAlign: 'start',
                }}
              >
                <Markup content={t(step.message1) + '&#x1F60A;'} />
              </div>

              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                  onClick={handleClose}
                >
                  OK
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  };

  return (
    <ModalWrapper
      headerTitle={t('changeContract')}
      openModal={openModalChangeContract}
      setModalWrapper={() => handleClose()}
    >
      {renderStep()}
    </ModalWrapper>
  );
}

export default ModalChangeContract;
