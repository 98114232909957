import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Request } from '../../../config/requests';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FullScreen from '../../../assets/iconsSmall/full-screen.svg';
import Collapse from '../../../assets/iconsSmall/collapse.svg';
import Close from '../../../assets/iconsSmall/close.svg';
import { isMobile } from 'react-device-detect';
import { Elements } from '@stripe/react-stripe-js';
import Payment from '../../payment/UpdatePayment';
import { getToken } from '../../../config/token';

function ModalStripe({
  openModalStripe,
  setOpenModalStripe,
  themeSetting,
  stripePromise,
  quoteId,
  policyId,
  insuredItemId,
  isCardPayment = true,
}) {
  const [isExpand, setIsExpand] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const userInfo = useSelector((state) => state.form.user);
  const token = getToken();

  const appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: 'rgb(226 75 77)',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
    },
    rules: {
      '.Input': {
        lineHeight: 'normal',
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
    locale: 'de',
  };

  const style = {
    position: 'absolute',
    left: '50%',
    width: isMobile ? '90%' : '70%',
    boxShadow: 12,
    p: 4,
    borderRadius: isMobile ? '20px' : '45px',
    backgroundColor: 'var(--b-color-bg-main-1) !important',
    padding: isMobile ? '20px' : '40px',
    transform: 'translateX(-50%)',
    margin: '5vh 0 0 0',
    outline: 'none',
  };

  const styleFS = {
    position: isMobile ? 'absolute' : 'relative',
    width: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    boxShadow: 12,
    p: 4,
    borderRadius: isMobile ? '20px' : '45px',
    backgroundColor: 'var(--b-color-bg-main-1) !important',
    padding: isMobile ? '20px' : '40px',
    margin: isMobile ? '20px 0' : '40px 0 0 0',
    outline: 'none',
  };

  useEffect(() => {
    async function createSetupIntent() {
      try {
        const res = await Request({
          method: 'post',
          token: token,
          route: 'api/payment/create_setup_intent',
          values: {
            email: userInfo.email,
            policyId: policyId,
            cardEnabled: isCardPayment,
          },
        });
        const data = await res.json();
        setClientSecret(data.clientSecret);
        setCustomerId(data.customerId);
        setSubscriptionId(data.subscriptionId);
      } catch (error) {
        console.error('Error fetching client secret:', error);
      }
    }

    createSetupIntent();
  }, [token, userInfo, quoteId, insuredItemId]);

  function handleClose() {
    setOpenModalStripe(false);
  }

  return (
    <div className="ContainerVendor" data-theme={themeSetting}>
      <Modal
        open={openModalStripe}
        onClose={handleClose}
        className="ModalCustom"
      >
        <Box sx={isExpand ? styleFS : style} data-theme={themeSetting}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 0 20px 0',
            }}
          >
            <div>
              <button
                className="btn-reset"
                onClick={() => setIsExpand(!isExpand)}
              >
                {isExpand ? (
                  <img
                    src={Collapse}
                    width={isMobile ? 18 : 34}
                    height={isMobile ? 18 : 34}
                  />
                ) : (
                  <img
                    src={FullScreen}
                    width={isMobile ? 18 : 34}
                    height={isMobile ? 18 : 34}
                  />
                )}
              </button>
              <button className="btn-reset" onClick={handleClose}>
                <img
                  src={Close}
                  width={isMobile ? 18 : 34}
                  height={isMobile ? 18 : 34}
                />
              </button>
            </div>
          </Box>
          <Box>
            {clientSecret && (
              <Elements stripe={stripePromise} options={options}>
                <Payment
                  quoteId={quoteId}
                  policyId={policyId}
                  insuredItemId={insuredItemId}
                  setOpenModalStripe={setOpenModalStripe}
                  clientSecret={clientSecret}
                  customerId={customerId}
                  subscriptionId={subscriptionId}
                />
              </Elements>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalStripe;
