import React, { useEffect, useState } from 'react';
import Loading from '../../../components/common/Loading';
import { useTranslation } from 'react-i18next';
import { Request } from '../../../config/requests';
import { getToken } from '../../../config/token';
import { Box } from '@mui/material';
import Header from '../../../components/common/Header';
import Footer from '../../../components/common/Footer';
import { isMobile } from 'react-device-detect';
import PawUI from '../../../components/ui/PawUI';
import PaymentTable from './PaymentTable';
import { showToast } from '../../../config/helpers';
import ModalProcessXML from '../../claims/modals/ModalProcessXML';
import ModalGeneratePayments from '../../claims/modals/ModalGeneratePayments';

function PaymentPage() {
  const { t } = useTranslation();
  const token = getToken();
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isLoadingRefunds, setIsLoadingRefunds] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingXML, setIsLoadingXML] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [refundDetails, setRefundDetails] = useState([]);
  const [enableProcessXML, setEnableProcessXML] = useState(false);
  const [openModalProcessXML, setOpenModalProcessXML] = useState(false);
  const [openModalGeneratePayments, setOpenModalGeneratePayments] = useState(false);
  const [numberOfClaims, setNumberOfClaims] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [refundOpen, setRefundOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [loadedPages, setLoadedPages] = useState(new Map());
  const pageSize = 10;

  const getUnpaidList = (currentPage = 1) => {
    setIsLoadingPayment(true);

    if (loadedPages.has(currentPage)) {
      const { claims } = loadedPages.get(currentPage)
      setPaymentDetails(claims);
      setIsLoadingPayment(false);
      return;
    }

    const queryString = `?page=${currentPage}&pageSize=${pageSize}`;

    Request({
      method: 'get',
      route: `api/report/list${queryString}`,
      token,
    })
      .then((response) => {
        if (!response.ok) {
          showToast(t('somethingWentWrong'), { error: true });
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setLoadedPages((prev) => new Map(prev).set(currentPage, json));
        setPaymentDetails(json.claims);
      })
      .catch((err) => {
        console.error('Error getUnpaidList:', err);
      })
      .finally(() => {
        setIsLoadingPayment(false);
      });
  };

  const getRefundList = () => {
    setIsLoadingRefunds(true);
    Request({ method: 'get', route: 'api/report/list-payments', token })
      .then((response) => {
        if (!response.ok) {
          showToast(t('somethingWentWrong'), { error: true });
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setRefundDetails(json);
      })
      .catch((err) => {
        console.error('Error getRefundList:', err);
      })
      .finally(() => {
        setIsLoadingRefunds(false);
      });
  };

  const getReportExcel = () => {
    setIsLoadingExcel(true);
    Request({ method: 'get', route: 'api/report/report-excel', token })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to download Excel file');
        }
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.xlsx';
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        showToast(t('somethingWentWrong'), { error: true });
        console.error('Error getReportExcel:', error);
      })
      .finally(() => {
        setIsLoadingExcel(false);
      });
  };

  const processXML = () => {
    setIsLoadingXML(true);
    const selectedItems = (refundOpen ? refundDetails : paymentDetails)
      .filter((el) => el?.selected === true)
      .map((el) => el.ID || el.policyID);
    Request({
      method: 'post',
      route: 'api/report/report-xml',
      token,
      values: {
        ids: selectedItems,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to create XML file');
        }
        return response.text();
      })
      .then((data) => {
        const blob = new Blob([data], {
          type: 'application/xml',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const filename = `payment-${new Date().toISOString()}.xml`;
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        showToast(t('createdXMLok'));
        // getUnpaidList();
      })
      .catch((error) => {
        showToast(t('somethingWentWrong'), { error: true });
        console.error('Error processXML:', error);
      })
      .finally(() => {
        setIsLoadingXML(false);
      });
  };

  const generatePayments = () => {
    setIsLoadingXML(true);
    const selectedItems = (refundOpen ? refundDetails : paymentDetails)
      .filter((el) => el?.selected === true)
      .map((el) => el.ID || el.policyID);

    Request({
      method: 'post',
      route: 'api/report/report-pay-claims',
      token,
      values: {
        ids: selectedItems,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to create XML file');
        }
        return response?.text();
      })
      .then((text) => {
        console.log('Response from server:', text);
        if (text) {
          showToast(text, { error: true });
        } else {
          showToast(t('paymentsCreate'));
        }

        if (refundOpen) {
          getRefundList()
        } else {
          setLoadedPages(new Map());
          setPaymentDetails([]);
          getUnpaidList(currentPage);
        }
        
      })
      .catch((error) => {
        showToast(t('somethingWentWrong'), { error: true });
        console.error('Error processXML:', error);
      })
      .finally(() => {
        setIsLoadingXML(false);
      });
  };

  useEffect(() => {
    getUnpaidList();
    getRefundList();
  }, []);

  useEffect(() => {
    if ((refundOpen ? refundDetails : paymentDetails).length > 0) {
      const anySelected = (refundOpen ? refundDetails : paymentDetails).some(
        (obj) => obj.selected === true
      );
      setEnableProcessXML(anySelected);
      if (anySelected) {
        const claimCount = (refundOpen ? refundDetails : paymentDetails).filter(
          (el) => el?.selected === true
        ).length;
        setNumberOfClaims(claimCount);

        const totalSum = (refundOpen ? refundDetails : paymentDetails)
          .filter((el) => el?.selected === true)
          .reduce((acc, el) => {
            const amount = el?.['Decision Amount'] || el?.['amount'] || 0;
            return (acc += amount);
          }, 0);
        setTotalAmount(Math.round(totalSum * 100) / 100);
      } else {
        setNumberOfClaims(0);
        setTotalAmount(0);
      }
    }
  }, [refundOpen ? refundDetails : paymentDetails]);

  return (
    <div className="main-container SlowShow">
      <Header />
      <div className="page-content relative claims-content">
        <div className="claims">
          {isMobile ? (
            <>
              <PawUI
                top="1vh"
                right="6vw"
                rotation="right"
                width="25vw"
                zIndex="0"
              />
              <PawUI
                top="53vh"
                left="-20vw"
                rotation="right"
                width="60vw"
                zIndex="0"
              />
              <PawUI
                bottom="-23vh"
                right="-27vw"
                rotation="left"
                width="70vw"
                zIndex="0"
              />
            </>
          ) : (
            <>
              <PawUI
                top="20vh"
                right="1vw"
                rotation="right"
                width="350px"
                zIndex="0"
              />
              <PawUI
                bottom="-10vh"
                left="5vw"
                rotation="left"
                width="150px"
                zIndex="0"
              />
              <PawUI
                top="10vh"
                left="20vw"
                rotation="left"
                width="250px"
                zIndex="0"
              />
            </>
          )}
          <div className="w-100 my-3 d-flex justify-content-between dark_red_title relative">
            {t('paymentPage')}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
              }}
            >
              <button
                className="button_red"
                style={{
                  minWidth: '220px',
                  margin: '0',
                }}
                onClick={() => setRefundOpen(!refundOpen)}
              >
                {isLoadingExcel ? (
                  <Loading noText fast scroll={false} color={'#fff'} />
                ) : (
                  t(refundOpen ? 'Payments' : 'Refunds')
                )}
              </button>
              <button
                className="button_red"
                style={{
                  minWidth: '220px',
                  margin: '0',
                }}
                onClick={() => getReportExcel()}
              >
                {isLoadingExcel ? (
                  <Loading noText fast scroll={false} color={'#fff'} />
                ) : (
                  t('exportExcel')
                )}
              </button>
              <button
                className={
                  enableProcessXML ? 'button_red' : 'button_red_disabled'
                }
                style={{
                  width: '220px',
                  margin: '0',
                }}
                disabled={!enableProcessXML}
                onClick={() => setOpenModalProcessXML(true)}
              >
                {isLoadingXML ? (
                  <Loading noText fast scroll={false} color={'#fff'} />
                ) : (
                  t('processXML')
                )}
              </button>
              <button
                className={
                  enableProcessXML ? 'button_red' : 'button_red_disabled'
                }
                style={{
                  width: '220px',
                  margin: '0',
                }}
                disabled={!enableProcessXML}
                onClick={() => setOpenModalGeneratePayments(true)}
              >
                {isLoadingXML ? (
                  <Loading noText fast scroll={false} color={'#fff'} />
                ) : (
                  t('generatePayments')
                )}
              </button>
            </Box>
          </div>
          {(refundOpen ? isLoadingRefunds : isLoadingPayment) ? (
            <Loading noText fast scroll={false} height="30vh" />
          ) : (
            <PaymentTable
              tableDetails={refundOpen ? refundDetails : paymentDetails}
              setTableDetails={
                refundOpen ? setRefundDetails : setPaymentDetails
              }
              paymentsMode={!refundOpen}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              loadedPages={loadedPages}
              getUnpaidList={getUnpaidList}
            />
          )}
        </div>
      </div>
      <Footer />
      {openModalProcessXML && (
        <ModalProcessXML
          openModalProcessXML={openModalProcessXML}
          setOpenModalProcessXML={setOpenModalProcessXML}
          processXML={processXML}
          numberOfClaims={numberOfClaims}
          totalAmount={totalAmount}
        />
      )}
      {openModalGeneratePayments && (
        <ModalGeneratePayments
          openModalGeneratePayments={openModalGeneratePayments}
          setOpenModalGeneratePayments={setOpenModalGeneratePayments}
          generatePayments={generatePayments}
          numberOfClaims={numberOfClaims}
          totalAmount={totalAmount}
        />
      )}
    </div>
  );
}

export default PaymentPage;
