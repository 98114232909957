import moment from 'moment';
import 'moment-timezone';

export const GENDER_ENUM = {
  Male: 'MALE',
  Female: 'FEMALE',
  Miscellaneous: 'MISCELLANEOUS',
  not_applicable: 'NOT_APPLICABLE',
};

export const HEALTH_CONDITION_ENUM = {
  health_no_illness: 'HEALTH_NO_ILLNESS',
  'Healthy and no illnesses in the last 6 months':
    'Healthy and no illnesses in the last 6 months',
};

const GLOBAL_SPICES = { Hund: 'DOG', Katze: 'CAT' };

const beginnLimits = {
  minYear: moment().add(1, 'day'),
  maxYear: moment().add(1, 'years'),
};

const geburtsdatumnLimits = {
  minYear: moment().add(-99, 'years'),
  maxYear: moment().add(-18, 'years'),
};

export const getYears = ({ beginn, geburtsdatum }) => {
  let maxYear = moment().add(-8, 'weeks').format('YYYY');
  let minYear = moment().add(-8, 'years').add(-363, 'day').format('YYYY');

  if (geburtsdatum) {
    maxYear = geburtsdatumnLimits.maxYear.format('YYYY');
    minYear = geburtsdatumnLimits.minYear.format('YYYY');
  }

  if (beginn) {
    maxYear = beginnLimits.maxYear.format('YYYY');
    minYear = beginnLimits.minYear.format('YYYY');
  }

  const options = [];
  if (geburtsdatum || beginn) {
    for (let i = maxYear; i >= minYear; i--) {
      options.push({ label: i, value: i });
    }
  } else {
    for (let i = maxYear; i >= minYear; i--) {
      options.push({ label: i, value: i });
    }
  }
  options.unshift({ label: 'JJJJ', value: 'JJJJ', isDisabled: true });
  return options;
};

const createList = (months) => {
  let options = [];
  let list = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];
  for (let i = 1; i <= months; i++) {
    options.push({ label: list[i - 1], value: i });
  }
  return options;
};

export const getMonthLabel = (monthValue) => {
  let list = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];
  return list[monthValue - 1];
};

const createDaysList = (days) => {
  let options = [];
  for (let i = 1; i <= days; i++) {
    let t = i < 10 ? '0' + i : i;
    options.push({ label: t, value: i });
  }
  return options;
};

export const getMonths = (year, name) => {
  let options = [];
  let maxYear = moment().add(-8, 'weeks').toObject();
  let minYear = moment().add(-8, 'years').add(-363, 'day').toObject();

  if (name === 'geburtsdatum') {
    maxYear = geburtsdatumnLimits.maxYear.toObject();
    minYear = geburtsdatumnLimits.minYear.toObject();
  }

  if (name === 'beginn') {
    maxYear = beginnLimits.maxYear.toObject();
    minYear = beginnLimits.minYear.toObject();
  }

  if (minYear.years == year) {
    let list = [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ];
    for (let i = minYear.months + 1; i <= 12; i++) {
      options.push({ label: list[i - 1], value: i });
    }
  } else {
    let months = maxYear.years == year ? maxYear.months + 1 : 12;
    options = createList(months);
  }
  options.unshift({ label: 'MM', value: 'MM', isDisabled: true });
  return options;
};

export const getDays = ({ year, month, name }) => {
  let options = [];
  let maxYear = moment().add(-8, 'weeks').toObject();
  let minYear = moment().add(-8, 'years').add(-363, 'day').toObject();

  if (name === 'geburtsdatum') {
    maxYear = geburtsdatumnLimits.maxYear.toObject();
    minYear = geburtsdatumnLimits.minYear.toObject();
  }

  if (name === 'beginn') {
    maxYear = beginnLimits.maxYear.toObject();
    minYear = beginnLimits.minYear.toObject();
  }

  if (minYear.years == year && minYear.months + 1 == month) {
    for (
      let i = minYear.date;
      i <= moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
      i++
    ) {
      let t = i < 10 ? '0' + i : i;
      options.push({ label: t, value: i });
    }
  } else {
    let days =
      maxYear.years == year && maxYear.months + 1 == month
        ? maxYear.date
        : moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
    options = createDaysList(days);
  }
  options.unshift({ label: 'TT', value: 'TT', isDisabled: true });
  return options;
};

export const parseDate = (date, name) => {
  const dateFormat = moment(date, 'YYYY-MM-DD').toObject();
  let years;
  let months;
  let days;
  if (name === 'geburtsdatum') {
    years = getYears({ geburtsdatum: true });
    months = getMonths(dateFormat.years, 'geburtsdatum');
    days = getDays({
      year: dateFormat.years,
      month: dateFormat.months + 1,
      name: 'geburtsdatum',
    });
  } else if (name === 'birthDate') {
    years = getYears({ beginn: false, geburtsdatum: false });
    months = getMonths(dateFormat.years, 'birthDate');
    days = getDays({
      year: dateFormat.years,
      month: dateFormat.months + 1,
      name: 'birthDate',
    });
  }

  return {
    years,
    months,
    days,
    dateFormated: dateFormat,
  };
};

export const formateDate = (date) => {
  return moment(date, 'YYYY-M-D').format('YYYY-MM-DD');
};

export const getSpices = ({ spices }) => GLOBAL_SPICES[spices];

export const getBeginnYears = () => {
  return getYears({ beginn: true });
};

export const getGeburtsdatumYears = () => {
  return getYears({ geburtsdatum: true });
};

export const srtingToNumConverter = (str) => {
  if (typeof str !== 'string') {
    return str;
  }
  return parseFloat(str.replace(',', '.'));
};

export const numToStringConverter = (num) => {
  if (typeof num !== 'number') {
    return num;
  }
  return num.toFixed(2).replace('.', ',');
};