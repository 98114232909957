const defaultState = {
  singleClaim: {},
  flowCurrentStep: 0,
  flowQuestions: [],
  additionalFlowQuestions: [],
  arrows: [],
  showOcrInvoicePopup: false,
  fillLater: null,
  isFlowGroup: false,
  flowSteps: [],
  dropdowns: [],
  uploadValues: {},
  isSameTitle: false,
  selectedPetInfo: {},
  uploadedImages: [],
  flowCompletion: false,
  flowLoading: false,
  flowModalShow: false,
  translations: {},
  pets: [],
  flowType: '',
  selectedFlowTypeToSkip: '',
  isLastStep: false,
  docCounter: 0,
  missedChipNumber: true,
  missedDiagnose: false,
  wtdOpenFlag: false,
  tableDetails: [],
  existingTypeDocument: '',
  claimIDlist: [],
  analyzingImage: false,
};

function flowReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case 'UPDATE_SINGLE_CLAIM':
      return { ...state, singleClaim: action.data };
    case 'UPDATE_FLOW_CURRENT_STEP':
      return { ...state, flowCurrentStep: action.data };
    case 'UPDATE_OCR_POPUP':
      return { ...state, showOcrInvoicePopup: action.data };
    case 'UPDATE_FLOW_QUESTIONS':
      return { ...state, flowQuestions: action.data };
    case 'UPDATE_FLOW_COMPLETION':
      return { ...state, flowCompletion: action.data };
    case 'SET_FLOW_LOADING':
      return { ...state, flowLoading: action.data };
    case 'SET_MODAL_SHOW':
      return { ...state, flowModalShow: action.data };
    case 'UPDATE_FLOW_GROUP':
      return { ...state, isFlowGroup: action.data };
    case 'UPDATE_DROPDOWNS':
      return { ...state, dropdowns: action.data };
    case 'UPDATE_FLOW_STEPS':
      return { ...state, flowSteps: action.data };
    case 'UPDATE_UPLOAD_VALUES':
      return { ...state, uploadValues: action.data };
    case 'UPDATE_SAME_TITLE':
      return { ...state, isSameTitle: action.data };
    case 'UPDATE_ARROWS':
      let arrows = [...state.arrows, action.data];
      if (state.arrows.some((i) => i.subFlowId === action.data.subFlowId))
        arrows = state.arrows.map((i) =>
          i.subFlowId === action.data.subFlowId ? { ...action.data } : i
        );
      return { ...state, arrows: [...arrows] };
    case 'CLEAR_ARROWS':
      return { ...state, arrows: [] };
    case 'UPDATE_FILL_LATER':
      return { ...state, fillLater: action.data };
    case 'UPDATE_SELECTED_PET_INFO':
      return { ...state, selectedPetInfo: action.data };
    case 'UPLOAD_IMAGES':
      return { ...state, uploadedImages: action.data };
    case 'SET_TRANSLATIONS':
      return { ...state, translations: action.data };
    case 'SET_PETS':
      return { ...state, pets: action.data };
    case 'SET_FLOW_TYPE':
      return { ...state, flowType: action.data };
    case 'SET_SELECTED_FLOW_TYPE_TO_SKIP':
      return { ...state, selectedFlowTypeToSkip: action.data };
    case 'UPDATE_IS_LAST_STEP':
      return { ...state, isLastStep: action.data };
    case 'UPDATE_DOC_COUNTER':
      return { ...state, docCounter: action.data };
    case 'UPDATE_MISSED_CHIP':
      return { ...state, missedChipNumber: action.data };
    case 'UPDATE_MISSED_DIAG':
      return { ...state, missedDiagnose: action.data };
    case 'UPDATE_WTD_MODAL_OPEN':
      return { ...state, wtdOpenFlag: action.data };
    case 'UPDATE_TABLE_DETAILS':
      return { ...state, tableDetails: action.data };
    case 'UPDATE_EXISTING_TYPE_DOC':
      return { ...state, existingTypeDocument: action.data };
    case 'UPDATE_CLAIM_ID_LIST':
      return { ...state, claimIDlist: action.data };
    case 'UPDATE_ANALYZING_IMAGE':
      return { ...state, analyzingImage: action.data };

    default:
      return state;
  }
}

export default flowReducer;
