import React from 'react';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import NewUpload from './new-upload';
import Loading from '../components/common/Loading';
import { useSelector } from 'react-redux';

export default function ContactClaim() {
  const analyzingImage = useSelector((s) => s.flow.analyzingImage);

  return (
    <>
      {analyzingImage && <Loading noText fullscreen />}
      <div className="main-container">
        <Header />
        <div className="page-content">
          <div className="claim__content">
            <NewUpload />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
