import React, { useEffect, useState } from 'react';
import { CHANGE_EMAIL_BIRTHDAY_FLOW } from '../../../constants/AdminFlows';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../components/inputs/select/CustomSelect';
import Select from 'react-select';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import {
  generateDays,
  generateYears,
  generateMonths,
} from '../../../constants/Flow';
import { Request } from '../../../config/requests';

export const Email_birthChange = ({ setIsLoading, isLoading, handleClose, setIsDone }) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = React.useState({
    label: 'E-Mail-Adresse',
    value: 'email',
  });
  const [date, setDate] = useState({ year: null, month: null, day: null });
  const [years, setYears] = useState(null);
  const [months, setMonths] = useState(null);
  const [days, setDays] = useState(null);
  const [newEmail, setNewEmail] = useState(null);
  const [email, setEmail] = useState(null);
  const [initailSelectedUser, setInitialSelectedUser] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [error, setError] = useState(null);
  const theme = 'dark';
  const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const getCustomerByEmail = async () => {
    setIsLoading(true);
    return await Request({
      method: 'get',
      route: `api/users/customer?email=${email}`,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.id) {
          setSelectedUser(res);
          setInitialSelectedUser(res);
          setNewEmail(res?.email);
          setYears({
            label: res?.birthDate.split('-')[0],
            value: res?.birthDate.split('-')[0],
          });
          setMonths({
            label: res?.birthDate.split('-')[1],
            value: res?.birthDate.split('-')[1],
          });
          setDays({
            label: res?.birthDate.split('-')[2],
            value: res?.birthDate.split('-')[2],
          });
          setError(null);
        } else {
          setError(res?.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const updateCustomer = async () => {
    setIsLoading(true);
    let payloadObj = {
      ...selectedUser,
      email: newEmail,
      birthDay: `${days.value}.${months.value}.${years.value}`,
    };
    delete payloadObj.emailConfirmation
    delete payloadObj?.birthDate
    delete payloadObj?.birthday
    if (initailSelectedUser.email == newEmail) {
      delete payloadObj.email
    }
    await Request({
      method: 'post',
      route: `api/users/change_data_chat?email=${selectedUser?.email}`,
      values: payloadObj,
    }).then(res => res.json())
      .then(data => {
        if (data.data.id) {
          setIsDone(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
        // handleClose();
      });
  };
  const monthsOptions = React.useMemo(() => {
    return generateMonths(years?.label);
  }, [years?.value]);

  useEffect(() => {
    setMonths(
      monthsOptions.some((el) => el?.value == months?.value)
        ? months
        : monthsOptions[monthsOptions.length - 1]
    );
  }, [monthsOptions]);

  useEffect(() => {
    if (selectedItem == 'email') {
      setYears({
        label: initailSelectedUser?.birthDate.split('-')[0],
        value: initailSelectedUser?.birthDate.split('-')[0],
      });
      setMonths({
        label: initailSelectedUser?.birthDate.split('-')[1],
        value: initailSelectedUser?.birthDate.split('-')[1],
      });
      setDays({
        label: initailSelectedUser?.birthDate.split('-')[2],
        value: initailSelectedUser?.birthDate.split('-')[2],
      });
    } else {
      setNewEmail(initailSelectedUser?.email);
    }
  }, [selectedItem]);
  return (
    <div className="wrapper w-100 d-flex gap-4 flex-column h-auto ">
      {CHANGE_EMAIL_BIRTHDAY_FLOW.map((section, index) => {
        return (
          (section.formType == 'search' ||
            (section.formType != 'search' && selectedUser?.id)) && (
            <div key={`section-${index}`} className="section-container w-100">
              {Object.entries(section.components).map(
                ([key, step], stepIndex) => {
                  // Check if step is an object with a type property
                  if (typeof step === 'object' && step.type) {
                    if (
                      (step.type === 'text_input' && !step.dependency) ||
                      (step.type === 'text_input' &&
                        step.dependency == selectedItem.value)
                    ) {
                      return (
                        <div
                          key={`text-step-${stepIndex}`}
                          className="modal_container__body__inputs align-items-start"
                        >
                          <label htmlFor={step.id}>{t(step.text)}</label>
                          <input
                            id={step.id}
                            className="modal_container__body__input w-100"
                            onChange={(e) =>
                              section.formType == 'search'
                                ? setEmail(e.target.value)
                                : setNewEmail(e.target.value)
                            }
                            placeholder={t(step.placeholder)}
                            required={step.required || false}
                            value={
                              section.formType == 'search' ? email : newEmail
                            }
                          />
                          {error ? <span style={{
                            color: '#FE4B4E',
                            fontWeight: '800',
                          }}>{error}</span> : ''}
                        </div>
                      );
                    }
                    if (step.type === 'button') {
                      return (
                        <div
                          key={`button-step-${stepIndex}`}
                          className="modal_container__body__inputs align-items-start"
                        >
                          <button
                            id={step.id}
                            onClick={() => {
                              section.formType == 'search' &&
                                getCustomerByEmail();
                            }}
                            disabled={
                              section.formType != 'search' &&
                              !String(email)
                                .toLocaleLowerCase()
                                .match(emailRegEx) &&
                              !email
                            }
                            className={`button_red_small__admin ${step.btnType || ''
                              }`}
                          >
                            {step.text}
                          </button>
                        </div>
                      );
                    }
                    if (step.type == 'select') {
                      return (
                        <>
                          <div
                            key={`button-step-${stepIndex}`}
                            className="modal_container__body__inputs align-items-start"
                          >
                            <label htmlFor={step.id}>{t(step.text)}</label>
                            <CustomSelect
                              variant="modal-edit-data"
                              options={step.options}
                              value={selectedItem}
                              onChange={(e) => {
                                setSelectedItem(e);
                                console.log(e);
                              }}
                              isClearable={true}
                              toTranslate={false}
                            />
                          </div>
                        </>
                      );
                    }
                    if (
                      step.type == 'date' &&
                      step.dependency == selectedItem.value
                    ) {
                      return (
                        <>
                          <div
                            key={`button-step-${stepIndex}`}
                            className="modal_container__body__inputs align-items-start w-100 "
                          >
                            <label htmlFor={step.id}>{t(step.text)}</label>
                            <div className="d-flex gap-3 w-100">
                              {/* {JSON.stringify(years)}<br></br>
                            {JSON.stringify(months)}<br></br>
                            {JSON.stringify(days)}<br></br> */}
                              <CustomSelect
                                variant="modal-edit-data"
                                options={generateYears(
                                  moment().format("YYYY-MM-DD"),
                                  moment()
                                    .subtract(100, 'years')
                                    .format('YYYY-MM-DD')
                                )}
                                formatCreateLabel={(i) => i.value}
                                placeholder={t('YY')}
                                value={years}
                                onChange={(e) => setYears(e)}
                                isClearable={false}
                                toTranslate={false}
                              />
                              <CustomSelect
                                variant="modal-edit-data"
                                options={monthsOptions}
                                formatCreateLabel={(i) => i.value}
                                placeholder={t('MM')}
                                value={months}
                                onChange={(e) => setMonths(e)}
                                toTranslate={false}
                                isClearable={false}
                              />
                              <CustomSelect
                                variant="modal-edit-data"
                                options={generateDays(
                                  years?.value,
                                  months?.value
                                )}
                                formatCreateLabel={(i) => i.value}
                                placeholder={t('DD')}
                                value={days}
                                onChange={(e) => setDays(e)}
                                toTranslate={false}
                                isClearable={false}
                              />
                            </div>
                          </div>
                        </>
                      );
                    }
                  }
                  return null; // Ignore non-object steps or steps without a type
                }
              )}
            </div>
          )
        );
      })}
      {selectedUser.id &&
        (newEmail != initailSelectedUser.email ||
          `${years.value}-${months.value}-${days.value}` !=
          initailSelectedUser.birthDate) && (
          <button
            onClick={() => {
              updateCustomer();
            }}
            className={`button_red_small__admin`}
            style={{ marginBottom: '10px' }}
          >
            Bestätigen
          </button>
        )}
    </div>
  );
};
